import React from 'react';
import i18n from '../../i18n';
import Fade from 'react-reveal/Fade'

/**Components Used */
import Header from '../Header';
import RowLeftPictGoogleBadge from '../Rows/RowLeftPictGoogleBadge';
import RowRightPict from '../Rows/RowRightPict';

/**Assets used */
import screen1 from '../../Assets/logo_environmentquiz.webp'
/**NE PAS ECRIRE UN ASSET AVEC CHATBOT EN MINUSCULE NE MARCHE PAS EN PROD ... */
import screen2 from '../../Assets/CHATBOT-4736275_1280.png'


function OtherApps(){
    return(
        <React.Fragment>
        <Header title="otherapps" numero={1}/>

<Fade bottom>         
            <RowLeftPictGoogleBadge
                urlbadge="https://play.google.com/store/apps/dev?id=7877346628801001455"
                smallerimagesize={true}
                imagealt="environment quiz"
                image={screen1}
                title={i18n.t('otherapps.title1')}
                description={i18n.t('otherapps.description1')} />

                <hr/>

            <RowRightPict
                imagealt="chatbot"
                smallerimagesize={true}
                image={screen2}
                title={i18n.t('otherapps.title2')}
                description={i18n.t('otherapps.description2')} />   
</Fade>

        </React.Fragment>
    )
}

export default OtherApps