import React from 'react'

function RowBGLeftPict({imagealt, image, title, description, android}){
    return(
        !android ? (<div className="row row_backgroundimg_player">
                    <div className="row_rightorleft">
                        {!title || title === '' ? (null):(<h2 className='row_title'>{ title  }</h2>)}
                        {!description || description === '' ? (null):(<p className='row_description' dangerouslySetInnerHTML={{__html: description}}></p>)}
                    </div>
                    <img className='row_imagewithbg30deg rotate0deg' alt={imagealt} src={image} /> 
                </div>
        )  : (<div className="row row_backgroundimg_android">
                <div className="row_rightorleft">
                    {!title || title === '' ? (null):(<h2 className='row_title'>{ title  }</h2>)}
                    {!description || description === '' ? (null):(<p className='row_description' dangerouslySetInnerHTML={{__html: description}}></p>)}
                </div>
                <img className='row_imagewithbg30deg rotate0deg' alt={imagealt} src={image} loading='lazy' /> 
            </div>
            )

            
    )
}

export default RowBGLeftPict