import React from 'react';
import { Trans } from 'react-i18next'
import i18n from '../i18n';

/**Assets used */
import instagram from '../Assets/icon_gs_instagram.webp'
import facebook from '../Assets/icon_gs_facebook.webp'
import twitter from '../Assets/icon_gs_twitter.webp'
import discord from '../Assets/icon_gs_discord.webp'
import youtube from '../Assets/icon_gs_youtube.webp'
import logoplaystore from '../Assets/badge_googleplay.webp'
import logoplaystorefr from '../Assets/fr_badge_googleplay.webp'
import logobarnaby from '../Assets/logo_barnabycompany.webp'

function Footer({coordonnees}) {
  return (
    <div className='footer-container'>
      <div className='footer-top'>
        <div className='footer-topleft'>
          <p><Trans i18nKey="footer.follow"/></p>
          <div className='footer-socialnetworklist'>
            <a href="https://www.instagram.com/environmentquiz/" target="_blank" rel="noopener noreferrer"><img alt='instagram' src={instagram} loading='lazy' /></a>
            <a href="https://www.facebook.com/environmentquiz" target="_blank" rel="noopener noreferrer"><img alt='facebook' src={facebook} loading='lazy' /></a>
            <a href="https://twitter.com/EnvironmentQuiz" target="_blank" rel="noopener noreferrer"><img alt='twitter' src={twitter} loading='lazy' /></a>
            <a href="https://discord.gg/f8PEZd37q5" target="_blank" rel="noopener noreferrer"><img alt='discord' src={discord}  loading='lazy'/></a>
            <a href="https://www.youtube.com/channel/UCJf3ZIXlLpsR5OwXsbhrP2A" target="_blank" rel="noopener noreferrer"><img alt='youtube' src={youtube} loading='lazy' /></a>
          </div>
        </div>
        <div className='footer-topright'>
          <a href='https://play.google.com/store/apps/details?id=fr.momiouo.naturequiz' target='_blank' rel="noopener noreferrer"><img className='footer-playstore-image' alt='playstore' src={i18n.languages[0] === "fr" ? logoplaystorefr : logoplaystore} loading='lazy' /></a>
        </div>
      </div>

      <hr className='footer-separation'/>

      <div className='footer-bot'>
          <a href="." ><Trans i18nKey="footer.terms"/></a>
          <a href="."><Trans i18nKey="footer.privacy"/></a>
      </div>

      <div className='footer-botbot'>
          <div className="coordonnees">
            <p dangerouslySetInnerHTML={{__html: coordonnees}}></p>
          </div>
          <div className="logodiv">
          <a href="." ><img className='logobarnabycomp' alt='logocompany' src={logobarnaby} loading='lazy' /></a> 
          </div>
        </div>
      </div>
  )
}

export default Footer