import React from 'react';
import i18n from '../../i18n';
import Fade from 'react-reveal/Fade'

/**Components Used */
import Header from '../Header';
import RowBGLeftPictMailSocialNetwork from '../Rows/RowBGLeftPictMailSocialNetwork';

/**Assets used */
import screen1 from '../../Assets/email.webp'

function ContactUs(){
    return(
        <React.Fragment>
            <Header title="contactus" numero={3} />

            <Fade left>         
            <RowBGLeftPictMailSocialNetwork
                imagealt="mail contact"
                image={screen1}
                title={i18n.t('contactus.title1')}
                description={i18n.t('contactus.description1')} />
            </Fade>         

        </React.Fragment>
    )
}

export default ContactUs