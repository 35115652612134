import i18n from "i18next";
import detector from "i18next-browser-languagedetector"
import { reactI18nextModule } from "react-i18next";

import translationEN from './languages/en/translation.json';
import translationFR from './languages/fr/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  }
};

i18n
  .use(detector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    whitelist: ['fr', 'en'],
    load: 'languageOnly',
    fallbackLng: "en", //use en if detected lng not available
    //lng: "en",

    keySeparator: '.', // we use keys in form: messages.welcome


    interpolation: {
      escapeValue: false // react already safes from xss
    },

    react: {
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'], // don't convert to <1></1> if simple react elements
    }

  });

export default i18n;