import React from "react";
import CuratorWidget from './Curator/CuratorWidget'


function InstaFeed(){
    return(
        <div className="row">
            <h2 className="row_title">Instagram Feed🧐</h2>
            <CuratorWidget feedId="e5a7258e-75fa-45d5-a93a-7c38f0c3a1ab"/>
        </div>
    )
}

export default InstaFeed