import React from 'react'
import i18n from '../../i18n'

/**Assets used */
import instagram from '../../Assets/icon_gs_instagram.webp'
import facebook from '../../Assets/icon_gs_facebook.webp'
import twitter from '../../Assets/icon_gs_twitter.webp'
import discord from '../../Assets/icon_gs_discord.webp'
import youtube from '../../Assets/icon_gs_youtube.webp'

function RowBGFood({imagealt, image, title, description}){
    return(
        <div className="row row_backgroundimg_food"> 
            <div className="row_rightorleft">
                {!title || title === '' ? (null):(<h2 className='row_title'>{ title  }</h2>)}
                {!description || description === '' ? (null):(<p className='row_description' dangerouslySetInnerHTML={{__html: description}}></p>)}
                <p className='row_description'>{i18n.t('environmentquiz.follow')}</p>
                <div className='socialnetworklist'>
                    <a href="https://www.instagram.com/environmentquiz/" target="_blank" rel="noopener noreferrer"><img  alt='instagram' src={instagram} /></a>
                    <a href="https://www.facebook.com/environmentquiz" target="_blank" rel="noopener noreferrer"><img  alt='facebook' src={facebook} /></a>
                    <a href="https://twitter.com/EnvironmentQuiz" target="_blank" rel="noopener noreferrer"><img  alt='twitter' src={twitter} /></a>
                    <a href="https://discord.gg/f8PEZd37q5" target="_blank" rel="noopener noreferrer"><img  alt='discord' src={discord} /></a>
                    <a href="https://www.youtube.com/channel/UCJf3ZIXlLpsR5OwXsbhrP2A" target="_blank" rel="noopener noreferrer"><img  alt='youtube' src={youtube} /></a>
                </div>
            </div>
            <img className='row_imagewithbg30deg image_minus15deg' alt={imagealt} src={image} loading='lazy' /> 
        </div>
    )
}

export default RowBGFood