import React from 'react'
import i18n from '../../i18n'

/**Assets used */
import logoplaystore from '../../Assets/badge_googleplay.webp'
import logoplaystorefr from '../../Assets/fr_badge_googleplay.webp'


function RowLeftPictGoogleBadge({imagealt, image, title, description, urlbadge, smallerimagesize}){
    return(
        <div className="row"> 
            <div className="row_rightorleft">
                {!title || title === '' ? (null):(<h2 className='row_title'>{ title  }</h2>)}
                {!description || description === '' ? (null):(<p className='row_description' dangerouslySetInnerHTML={{__html: description}}></p>)}
                <a href={urlbadge} target='_blank' rel="noreferrer"><img className='playstore_image' alt='playstore' src={i18n.languages[0] === "fr" ? logoplaystorefr : logoplaystore} /></a>
            </div>
            {!smallerimagesize ? (
                <img className='row_image image_minus15deg row_image_screen' alt={imagealt} src={image} loading='lazy' />
            ) : (
                <img className='row_image smallersize image_minus15deg' alt={imagealt} src={image} loading='lazy' />
            )
            }
             
        </div>
    )
}

export default RowLeftPictGoogleBadge