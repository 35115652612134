import React from 'react';
import i18n from '../../i18n';
import Fade from 'react-reveal/Fade'

/**Components Used */
import Header from '../Header';
import RowBGCodeLeftPictListLangages from '../Rows/RowBGCodeLeftPictListLangages';
import RowRightPict from '../Rows/RowRightPict';
import RowBGLeftPict from '../Rows/RowBGLeftPict';

/**Assets used */
import screen1 from '../../Assets/androidstudio.webp'
import screen2 from '../../Assets/reactnative.webp'
import screen3 from '../../Assets/krita.webp'
import screen4 from '../../Assets/startup.webp'


function Resources() {
    return (
        <React.Fragment>
            <Header title="resources" numero={4} />

<Fade right>
            <RowBGLeftPict
                android = "bgandroid"
                imagealt="android studio"
                image={screen1}
                title={i18n.t('resources.title1')}
                description={i18n.t('resources.description1')} />
</Fade>
<Fade left>
            <RowRightPict
                smallericonsize={true}
                smallerdescription={true}
                imagealt="react native"
                image={screen2}
                title={i18n.t('resources.title2')}
                description={i18n.t('resources.description2')} />
</Fade>
<Fade bottom>
            <RowBGCodeLeftPictListLangages
                imagealt="languages"
                image={screen4}
                title={i18n.t('resources.title3')}
                description={i18n.t('resources.description3')} />

            <RowRightPict
                smallericonsize={true}
                smallerdescription={true}
                imagealt="krita"
                image={screen3}
                title={i18n.t('resources.title4')}
                description={i18n.t('resources.description4')} />
</Fade>

  

        </React.Fragment>
    )
}

export default Resources