import React from 'react';
import i18n from '../../i18n';
import Fade from 'react-reveal/Fade'

/**Components Used */
import Header from '../Header';
import RowBGLeftPict from '../Rows/RowBGLeftPict';
import RowRightPict from '../Rows/RowRightPict';
import InstaFeed from '../InstaFeed';

/**Assets used */
import screen1 from '../../Assets/fusee.webp'
import screen2 from '../../Assets/detective.webp'


function AboutUs(){
    return(
        <React.Fragment>
        <Header title="aboutus" numero={2} />

<Fade bottom>       
            <RowBGLeftPict
                imagealt="startup rocket"
                image={screen1}
                title={i18n.t('aboutus.title1')}
                description={i18n.t('aboutus.description1')} />
                
                <InstaFeed/>

                <RowRightPict
                smallerimagesize={true}
                imagealt="aboutus inspector"
                image={screen2}
                title={i18n.t('aboutus.title2')}
                description={i18n.t('aboutus.description2')} />
</Fade>

</React.Fragment>
    )
}

export default AboutUs