import React from 'react'
import i18n from '../../i18n'

/**Assets used */
import html from '../../Assets/html.webp'
import css from '../../Assets/css.webp'
import js from '../../Assets/javascript.webp'
import java from '../../Assets/java.webp'


function RowBGCodeLeftPictListLangages({imagealt, image, title, description}){
    return(
        <div className="row row_backgroundimg_resources"> 
            <div className="row_rightorleft">
                {!title || title === '' ? (null):(<h2 className='row_title'>{ title  }</h2>)}
                {!description || description === '' ? (null):(<p className='row_description' dangerouslySetInnerHTML={{__html: description}}></p>)}
                <p className='row_description'>{i18n.t('resources.usedlanguages')}</p>
                <div className='socialnetworklist'>
                    <a href="https://en.wikipedia.org/wiki/HTML" target="_blank" rel="noopener noreferrer"><img  alt='html' src={html} /></a>
                    <a href="https://en.wikipedia.org/wiki/CSS" target="_blank" rel="noopener noreferrer"><img  alt='css' src={css} /></a>
                    <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" target="_blank" rel="noopener noreferrer"><img  alt='js' src={js} /></a>
                    <a href="https://www.java.com/en/" target="_blank" rel="noopener noreferrer"><img  alt='java' src={java} /></a>
                </div>
            </div>
            <img className='row_imagewithbg30deg' alt={imagealt} src={image} loading='lazy' /> 
        </div>
    )
}

export default RowBGCodeLeftPictListLangages
