import React from 'react';
import { Switch, Route} from 'react-router-dom';

/**Components (Pages) used */
import EnvironmentQuiz from '../Components/Pages/EnvironmentQuiz';
import OtherApps from '../Components/Pages/OtherApps';
import AboutUs from '../Components/Pages/AboutUs';
import ContactUs from '../Components/Pages/ContactUs';
import Resources from '../Components/Pages/Resources';


const Main = () => {
  return (
      <React.Fragment>
                <Switch > {/* The Switch decides which component to show based on the current URL.*/}
                    <Route exact path='/' component={EnvironmentQuiz}></Route>
                    <Route exact path='/otherapps' component={OtherApps}></Route>
                    <Route exact path='/aboutus' component={AboutUs}></Route>
                    <Route exact path='/contactus' component={ContactUs}></Route>
                    <Route exact path='/resources' component={Resources}></Route>
                </Switch>
    </React.Fragment>
  );
}

export default Main;