import React from 'react'

function RowRightPict({imagealt, image, title, description, smallerimagesize, smallerdescription, smallericonsize}){
    return(
        <div className="row row_reverse"> 

            {!smallerdescription ? (
            <div className="row_rightorleft">
                {!title || title === '' ? (null):(<h2 className='row_title'>{ title  }</h2>)}
                {!description || description === '' ? (null):(<p className='row_description' dangerouslySetInnerHTML={{__html: description}}></p>)}
            </div>
            ) : (
            <div className="row_rightorleft smaller_description">
                {!title || title === '' ? (null):(<h2 className='row_title'>{ title  }</h2>)}
                {!description || description === '' ? (null):(<p className='row_description' dangerouslySetInnerHTML={{__html: description}}></p>)}
            </div>
            )
            }

            

            {!smallerimagesize ? (
                !smallericonsize ? (
                    <img className='row_image image15deg row_image_screen' alt={imagealt} src={image} loading='lazy' />
                ):(
                    <img className='row_image image15deg smallericonsize' alt={imagealt} src={image} loading='lazy' />
                )
            ) : (
                <img className='row_image image15deg smallersize' alt={imagealt} src={image} loading='lazy' />
            )
            }
        </div>
    )
}

export default RowRightPict