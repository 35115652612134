import React from 'react'
import i18n from '../i18n';

/**Components used */
import Footer from './Footer'
import Main from './Main';

/**StyleSheet */
import '../Styles/App.css';
import '../Styles/Rows.css'
import '../Styles/Footer.css'
import '../Styles/Header.css'

function App() {
  return (
    <div className='App'>
      <Main/>
      <Footer coordonnees={i18n.t('footer.coordonnees')}/>
    </div>
  )
}


export default App;
