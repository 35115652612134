import React from 'react';
import i18n from '../../i18n';
import Fade from 'react-reveal/Fade'
import HeadShake from 'react-reveal/HeadShake'

/**Components Used */
import Header from '../Header';
import RowLeftPictGoogleBadge from '../Rows/RowLeftPictGoogleBadge';
import RowRightPict from '../Rows/RowRightPict';
import RowBGFood from '../Rows/RowBGFood'

/**Assets used */
import screen1 from '../../Assets/screen1.webp'
import screen2 from '../../Assets/screen2.webp'
import screen1fr from '../../Assets/fr_screen1.webp'
import screen2fr from '../../Assets/fr_screen2.webp'
import screen3 from '../../Assets/bio.webp'
import YoutubeEmbed from '../YoutubeEmbed';
//import video from '../../environment_quiz_fiverr_video.mp4'


function EnvironmentQuiz() {

    //console.log("Current langage: " + i18n.language)

    return (
        <React.Fragment>
            <Header title="environmentquiz" numero={0} />

<Fade bottom>
            <RowLeftPictGoogleBadge
                urlbadge="https://play.google.com/store/apps/details?id=fr.momiouo.naturequiz"
                imagealt="question1"
                image={i18n.languages[0] === "fr" ? screen1fr : screen1}
                title={i18n.t('environmentquiz.title1')}
                description={i18n.t('environmentquiz.description1')} />

            <hr />


            <RowRightPict
                imagealt="topics"
                image={i18n.languages[0] === "fr" ? screen2fr : screen2}
                title={i18n.t('environmentquiz.title2')}
                description={i18n.t('environmentquiz.description2')} />

            <RowBGFood
                imagealt="bio"
                image={screen3}
                title={i18n.t('environmentquiz.title3')}
                description={i18n.t('environmentquiz.description3')} />

</Fade>

<HeadShake>
            <YoutubeEmbed embedId="lQwtZgo8ugs" />
</HeadShake>
        </React.Fragment>
    )
}

export default EnvironmentQuiz