import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
//import { isBrowser, isMobile } from 'react-device-detect'
import { Trans } from 'react-i18next'

function Header({title, numero}) {

    useEffect(() => {
      //Update the selected element color after component did mount
      //console.log(numero)
      //console.log(document.getElementsByClassName('elemdunav'))
      document.getElementsByClassName('elemdunav')[numero].className = "elemdunav selected"
    });

    return (
    <div className='header-maincontainer'>
        <div className='header-titlebox'>
          <a className="header-title" href="."> <h1 className='title'><Trans i18nKey={"header."+title}/></h1> </a>
        </div>
        <div className="header-list">
            <Link to="/">
                <div className='elemdunav'>Environment Quiz</div>
            </Link>
          <div className="separation">-</div>
            <Link to="/otherapps">
                <div className='elemdunav'><Trans i18nKey="header.otherapps"/></div>
            </Link>
          <div className="separation">-</div>
            <Link to="/aboutus">
                <div className='elemdunav'><Trans i18nKey="header.aboutus"/></div>
            </Link>
          <div className="separation">-</div>
            <Link to="/contactus">
                <div className='elemdunav'><Trans i18nKey="header.contactus"/></div>
            </Link>
          <div className="separation">-</div>
            <Link to="/resources">
                <div className='elemdunav'><Trans i18nKey="header.resources"/></div>
            </Link>
        </div>
      </div>
      
    )
}




export default Header